import './CurrentBadge.css'

const CurrentBadge = () => {
  return (
    <>
    <div id="current-badge" className="current-badge">
      You're up!
    </div>
    </>
  )
}

export default CurrentBadge;
