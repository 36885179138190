export const PromptList = [
  "She let the balloon float up into the air with her hopes and dreams.",
  "It was getting dark, and we weren’t there yet.",
  "Three years later, the coffin was still full of Jello.",
  "The doll spun around in circles in hopes of coming alive.",
  "He drank life before spitting it out.",
  "He was willing to find the depths of the rabbit hole in order to be with her.",
  "He ran out of money, so he had to stop playing poker.",
  "Truth in advertising and dinosaurs with skateboards have much in common.",
  "Last Friday I saw a spotted striped blue worm shake hands with a legless lizard.",
  "The urgent care center was flooded with patients after the news of a new deadly virus was made public.",
  "He stomped on his fruit loops and thus became a cereal killer.",
  "Going from child, to childish, to childlike is only a matter of time.",
  "In that instant, everything changed.",
  "He set out for a short walk, but now all he could see were mangroves and water were for miles.",
  "The rusty nail stood erect, angled at a 45-degree angle, just waiting for the perfect barefoot to come along.",
  "The old apple revels in its authority.",
  "8% of 25 is the same as 25% of 8 and one of them is much easier to do in your head.",
  "His confidence would have been admirable if it wasn't for his stupidity.",
  "Dan ate the clouds like cotton candy.",
  "The Tsunami wave crashed against the raised houses and broke the pilings as if they were toothpicks.",
  "The elephant didn't want to talk about the person in the room.",
  "The secret code they created made no sense, even to them.",
  "She found his complete dullness interesting.",
  "This made him feel like an old-style rootbeer float smells.",
  "The clouds formed beautiful animals in the sky that eventually created a tornado to wreak havoc.",
  "He didn't understand why the bird wanted to ride the bicycle.",
  "It was a slippery slope and he was willing to slide all the way to the deepest depths.",
  "He learned the hardest lesson of his life and had the scars, both physical and mental, to prove it.",
  "This book is sure to liquefy your brain.",
  "Nobody loves a pig wearing lipstick.",
  "Please wait outside of the house.",
  "She had a habit of taking showers in lemonade.",
  "He would only survive if he kept the fire going and he could hear thunder in the distance.",
  "Her daily goal was to improve on yesterday.",
  "If I don’t like something, I’ll stay away from it.",
  "There was no telling what thoughts would come from the machine.",
  "The breeze smelled like maple syrup.",
  "The nap took him into the next day.",
  "So that's where the oreo went!",
  "She was disgusted he couldn’t tell the difference between lemonade and limeade.",
  "Words are wind.",
  "Garlic ice cream was her favorite.",
  "All she wanted was the answer, but she had no idea how much she would hate it.",
  "It was at that moment that he learned there are certain parts of the body that you should never Nair.",
  "All they could see was the blue water surrounding their sailboat.",
  "He was 100% into fasting with her until he understood that meant he couldn't eat.",
  "I currently have 4 windows open up… and I don’t know why.",
  "The door slammed on the watermelon.",
  "I purchased a baby clown from the Russian terrorist black market.",
  "Spelling Bee.",
  "She wrote him a long letter, but he didn't read it.",
  "Two more days and all his problems would be solved.",
  "I farted and hoped no one would notice.",
  "Fractured, but mending.",
  "There's an art to getting your way, and spitting olive pits across the table isn't it.",
  "He figured a few sticks of dynamite were easier than a fishing pole to catch fish.",
  "He was disappointed when he found the beach to be so sandy and the sun so sunny.",
  "If eating three-egg omelets causes weight-gain, budgie eggs are a good substitute.",
  "Art doesn't have to be intentional.",
  "The knives were out and she was sharpening hers.",
  "Grape jelly was leaking out the hole in the roof.",
  "When he had to picnic on the beach, he purposely put sand in other people’s food.",
  "There should have been a time and a place, but this wasn't it.",
  "Lightning Paradise was the local hangout joint where the group usually ended up spending the night.",
  "Greetings from the real universe.",
  "Her hair was windswept as she rode in the black convertible.",
  "My dentist tells me that chewing bricks is very bad for your teeth.",
  "I like to leave work after my eight-hour tea-break.",
  "He wore the surgical mask in public not to keep from catching a virus, but to keep people away from him.",
  "Pat ordered a ghost pepper pie.",
  "We will not allow you to bring your pet armadillo along.",
  "There are no heroes in a punk rock band.",
  "She lived on Monkey Jungle Road and that seemed to explain all of her strangeness.",
  "Joyce enjoyed eating pancakes with ketchup.",
  "Whenever he saw a red flag warning at the beach he grabbed his surfboard.",
  "Had he known what was going to happen, he would have never stepped into the shower.",
  "Weather is not trivial - it's especially important when you're standing in it.",
  "The three-year-old girl ran down the beach as the kite flew behind her.",
  "Now I need to ponder my existence and ask myself if I'm truly real.",
  "Her scream silenced the rowdy teenagers.",
  "There were three sphered rocks congregating in a cubed room.",
  "I’m a living furnace.",
  "We have young kids who often walk into our room at night for various reasons including clowns in the closet.",
  "He poured rocks in the dungeon of his mind.",
  "Flesh-colored yoga pants were far worse than even he feared.",
  "As the years pass by, we all know owners look more and more like their dogs.",
  "Each person who knows you has a different perception of who you are.",
  "He uses onomatopoeia as a weapon of mental destruction.",
  "He turned in the research paper on Friday; otherwise, he would have not passed the class.",
  "She felt that chill that makes the hairs on the back of your neck when he walked into the room.",
  "Various sea birds are elegant, but nothing is as elegant as a gliding pelican.",
  "He put heat on the wound to see what would grow.",
  "I am writing and I am going to stop mid-sent-",
  "The shooter says goodbye to his love.",
  "Sometimes it is better to just walk away from things and go back to them later when you’re in a better frame of mind.",
  "Improve your goldfish's physical fitness by getting him a bicycle.",
  "One small action would change her life, but whether it would be for better or for worse was yet to be determined.",
  "Jeanne wished she had chosen the red button.",
  "While on the first date he accidentally hit his head on the beam.",
  "Patricia loves the sound of nails strongly pressed against the chalkboard.",
  "There's a reason that roses have thorns.",
  "They wandered into a strange bar on the edge of the small beach town.",
  "They're playing the piano while flying in the plane.",
  "He's in a boy band, which doesn't make much sense for a snake.",
  "The memory we used to share is no longer coherent.",
  "Abstraction is often one floor above you.",
  "He enjoys practicing his ballet in the bathroom.",
  "Someone I know recently combined Maple Syrup & buttered Popcorn thinking it would taste like caramel popcorn. It didn’t and they don’t recommend anyone else do it either.",
  "She was sad to hear that fireflies are facing extinction due to artificial light, habitat loss, and pesticides.",
  "He wondered if she would appreciate his toenail collection.",
  "After exploring the abandoned building, he started to believe in ghosts.",
  "Choosing to do nothing is still a choice, after all.",
  "I'm confused: when people ask me what's up, and I point, they groan.",
  "Thigh-high in the water, the fisherman’s hope for dinner soon turned to despair.",
  "The body piercing didn't go exactly as he expected.",
  "The book is in front of the table.",
  "She looked into the mirror and saw another person.",
  "The pigs were insulted that they were named hamburgers.",
  "He found the end of the rainbow and was surprised at what he found there.",
  "The rain pelted the windshield as the darkness engulfed us.",
  "She wondered what his eyes were saying beneath his mirrored sunglasses.",
  "Even though he thought the world was flat he didn’t see the irony of wanting to travel around the world.",
  "She cried diamonds.",
  "In hopes of finding out the truth, he entered the one-room library.",
  "The fish dreamed of escaping the fishbowl and into the toilet where he saw his friend go.",
  "When nobody is around, the trees gossip about the people who have walked under them.",
  "At that moment she realized she had a sixth sense.",
  "The fox in the tophat whispered into the ear of the rabbit.",
  "Wisdom is easily acquired when hiding under the bed with a saucepan on your head.",
  "He walked into the basement with the horror movie from the night before playing in his head.",
  "She borrowed the book from him many years ago and hasn't yet returned it.",
  "The irony of the situation wasn't lost on anyone in the room.",
  "The view from the lighthouse excited even the most seasoned traveler.",
  "He is good at eating pickles and telling women about his emotional problems.",
  "Random words in front of other random words create a random sentence.",
  "There aren't enough towels in the world to stop the sewage flowing from his mouth.",
  "He realized there had been several deaths on this road, but his concern rose when he saw the exact number.",
  "The lake is a long way from here."
]

